<template>
  <div id="app">
	   <Topmenu v-if="showtop"></Topmenu>
	<router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
	import Topmenu from '@/components/Topmenu'
	import wx from "weixin-js-sdk"
	export default {
	 provide(){//刷新
			return{
			  reload:this.reload
			}
		  },
	  components: {
		  Topmenu
	  },
	  data() {
	  	return{
			isRouterAlive:true,
			username:'',
			showtop:true
		}
	  },
	  created() {
	  },
	  mounted() {
		   if(this.getCookie('usertoken')){
			    localStorage.setItem('tocken',this.getCookie('usertoken'))
		   }
	  },
	  watch:{
			$route(to , from){
				if( to.path =='/qrindex' ){
					this.showtop=false
				}else{
					this.showtop=true
				}
			}
	  },
	  methods:{
		 getCookie(cookieName) {
		    const strCookie = document.cookie
		    const cookieList = strCookie.split(';')
		    for(let i = 0; i < cookieList.length; i++) {
		      const arr = cookieList[i].split('=')
		      if (cookieName === arr[0].trim()) {
		        return arr[1]
		      }
		    }
		    return ''
		  },

		reload(){
			this.isRouterAlive=false;
			this.$nextTick(function(){
				this.isRouterAlive=true
			})
		}
	  }
	}
</script>

<style scoped>
	
</style>
